/* Historian.css */
.historian-container {
  width: calc(80% - 20px); /* Adjusted width to account for margins */
  float: left; /* To position the container on the left side */
  padding-top: 2%;
  margin: 0 10px; /* Added margin */
}

.filter-container {
  width: calc(20% - 20px); /* Adjusted width */
  height: 100vh;
  padding-top: 2%;
  margin: 0 10px; /* Added margin */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Arrange children vertically */
}

.filter-card {
  margin-top: 5%;
  border-radius: 0.5rem;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.3);
  flex: 1; /* Expand to fill remaining space */
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  font-size: 1.5rem; /* Adjust as needed */
  margin-bottom: 1rem; /* Add space between title and cards */
  margin-left: 10px; /* Align the left side of the title with the left side of the grid */
  font-weight: bold; /* Set font weight to bold */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Ensure font consistency */
  float: right;
}

.title {
  font-size: 3rem; /* Adjust as needed */
  margin-bottom: 1rem; /* Add space between title and cards */
  margin-left: 10px; /* Align the left side of the title with the left side of the grid */
  font-weight: bold; /* Set font weight to bold */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Ensure font consistency */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  grid-gap: 10px; /* Gap between grid items */
  height: calc(100% - 30%); /* Adjusted height to account for margins and padding */
}

.grid-item {
  margin: 3%;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  max-height: 100%;
}

.grid-item-img {
  width: 10%; /* Adjust icon width */
  margin-right: 3%; /* Add space between icon and text */
}

.grid-item-span {
  font-size: 1.4rem; /* Adjust text size as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Ensure font consistency */
  color: #32aca2;
  font-weight: bolder;
}

.graph-description {
  font-size: 1rem; /* Adjust text size as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Ensure font consistency */
  color: #32aca2;
  font-weight: bolder;
}

.grid-item-header {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically in the center */
  max-height: 15%;
  justify-content: space-between;
}

.grid-item-content {
  height: 85%;
  margin: 0;
  padding: 2%;
}

.header-content {
  display: flex;
  align-items: center;
}

.trilling-numbers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  padding: 0 4% 0 7%;
}

.number {
  font-size: 3rem;
  font-weight: bold;
}

.labels {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  font-weight: bold;
}

.dots {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 25%;
  font-weight: bold;
  padding: 0 10% 0 10%;
  font-size: 2rem;
  color: #32aca2;
}

.date-selectors {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto; /* Added to horizontally center the container */
}

.date-selector {
  width: 100%;
  margin-bottom: 10px;
  padding: 3%; /* Added padding to make the date selector bigger */
  font-family: "Segoe UI", Arial, sans-serif; /* Changed font-family to Segoe UI */
  font-size: 1.5rem; /* Adjust font size as needed */
  background-color: #32aca2; /* Set background color to #32aca2 */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border-radius for rounded corners */
  color: #ffffff; /* Set text color to white */
}

.filter-button-group {
  margin-top: 15%;
}

.filter-button {
  width: 100%;
  padding: 10px;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.5rem;
  background-color: #ff9130;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  align-self: flex-end;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
}

.filter-label {
  align-self: flex-start;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Segoe UI", Arial, sans-serif;
}

.additional-text {
  align-self: flex-end;
  font-weight: bold;
  font-family: "Segoe UI", Arial, sans-serif;
}
.filter-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.green-line {
  height: 3px;
  background-color: #32aca2;
  margin-bottom: 1rem;
}

.filter-table {
  width: 100%;
  border-collapse: collapse;
}

.filter-table th,
.filter-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.filter-table th {
  background-color: #f2f2f2;
}

.add-button {
  width: 10%;
  height: 10%;
  margin-top: 3%;
  border-radius: 50%;
  background-color: #32aca2;
  color: #fff;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  align-self: flex-end;
  cursor: pointer;
}

.actions-list {
  max-height: 80%;
  padding-left: 10%;
  overflow: scroll;
}
